const quotes = [
  "Es ist nie zu spät, für das zu kämpfen, was einem wirklich wichtig ist",
  "Nichts ist falsch, wenn dein Herz dir sagt, dass es richtig ist",
  "Eine ruhige See hat noch nie einen geschickten Seemann gemacht (Roosevelt)",
  "Familie ist wie ein Baum. Die Zweige mögen in unterschiedliche Richtungen wachsen, doch die Wurzeln halten alles zusammen!",
  "Den Liebenden mundet Wasser wie Wein",
  "Wenn die Steine schwimmen, versinken die Blätter (aus Japan)",
  "Auch aus Steinen, die einem in den Weg gelegt werden, kann man Schönes bauen (Johann Wolfgang von Goethe)",
  "Es gibt wenig aufrichtige Freunde - die Nachfrage ist auch gering (Marie von Ebner-Eschenbach)",
  "Dumme rennen, Kluge warten, Weise gehen in den Garten",
  "Der Kluge ärgert sich über die Dummheiten, der Weise belächelt sie",
  "Das einzige Mittel, Zeit zu haben, ist, sich Zeit zu nehmen",
  "Wer die Gegenwart genießt, hat in Zukunft eine wunderbare Vergangenheit",
  "Welches auch die Gaben sein mögen, mit denen du erfreuen kannst, erfreue (Ovid)",
  "Der Tropfen Geduld kann mehr erreichen als ein Ozean des Zorns",
  "In der Liebe sprechen Hände und Augen meist lauter als der Mund",
  "Mut steht am Anfang des Handelns, Glück am Ende (Demokrit)",
  "Der Spiegel offenbart dir nur, was du zu sehen bereit bist (Helga Schäferling)",
  "Ist man in kleinen Dingen nicht geduldig, bringt man die großen Vorhaben zum Scheitern (Konfuzius)",
  "Es ist jedem heilsam, sich auch einmal als Karikatur sehen zu können (Karl Gutzkow)",
  "Bildung beginnt mit Neugierde (Peter Bieri)",
  "Aber es gibt Niederlagen, die Siege sind; und Siege, verhängnisvoller als Niederlagen (Karl Liebknecht)",
  "Man empfängt Menschen nach dem Kleide und entlässt sie nach dem Verstand (Karl Simrock)",
  "Rutsch lieber mit dem Fuß aus als mit der Zunge (aus Bulgarien)",
  "Glück entsteht oft durch Aufmerksamkeiten in kleinen Dingen (Wilhelm Busch)"
]

module.exports = quotes;