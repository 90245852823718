module.exports = [
  {
    "top": 21,
    "left": 958
  },
  {
    "top": 439,
    "left": 57
  },
  {
    "top": 506,
    "left": 649
  },
  {
    "top": 315,
    "left": 144
  },
  {
    "top": 536,
    "left": 370
  },
  {
    "top": 407,
    "left": 469
  },
  {
    "top": 208,
    "left": 65
  },
  {
    "top": 390,
    "left": 996
  },
  {
    "top": 212,
    "left": 437
  },
  {
    "top": 18,
    "left": 703
  },
  {
    "top": 108,
    "left": 478
  },
  {
    "top": 515,
    "left": 814
  },
  {
    "top": 150,
    "left": 220
  },
  {
    "top": 56,
    "left": 829
  },
  {
    "top": 358,
    "left": 885
  },
  {
    "top": 49,
    "left": 367
  },
  {
    "top": 140,
    "left": 932
  },
  {
    "top": 266,
    "left": 286
  },
  {
    "top": 249,
    "left": 1049
  },
  {
    "top": 275,
    "left": 775
  },
  {
    "top": 590,
    "left": 926
  },
  {
    "top": 17,
    "left": 48
  },
  {
    "top": 31,
    "left": 185
  },
  {
    "top": 299,
    "left": 31
  }
];
